<template>
  <div>
    <el-upload
      drag
      v-show="editPic"
      class="UploadPic"
      action="#"
      accept=".png,.PNG,.jpeg,.jpg"
      :show-file-list="false"
      :auto-upload="false"
      :on-change="imgSaveToUrl"
    >
      <img v-if="imageUrl" :src="imageUrl" class="UploadPic-imageUrl" />
      <!-- 上传区域、提示 -->
      <div v-else class="UploadPic-explain">
        <div class="UploadPic-explain-icon">
          <i class="icon icon-addImg"></i
          ><span class="UploadPic-explain-icon-text">添加照片</span>
        </div>
        <span class="UploadPic-explain-text"
          >仅支持JPG/JPEG/PNG格式 且文件小于100Kb</span
        >
      </div>
    </el-upload>
    <div class="cropper" v-if="!editPic">
      <!-- 图片编辑器 -->
      <vueCropper
        ref="cropper"
        :img="option.img"
        :info="option.info"
        :outputSize="option.outputSize"
        :outputType="option.outputType"
        :canScale="option.canScale"
        :autoCrop="option.autoCrop"
        :autoCropWidth="option.autoCropWidth"
        :autoCropHeight="option.autoCropHeight"
        :fixed="option.fixed"
        :fixedBox="option.fixedBox"
        :fixedNumber="option.fixedNumber"
        :full="option.full"
        :canMove="option.canMove"
        :canMoveBox="option.canMoveBox"
        :original="option.original"
        :centerBox="option.centerBox"
        :infoTrue="option.infoTrue"
        :mode="option.mode"
        zoomable
        zoomOnWheel
      ></vueCropper>
    </div>
    <list-tooltip content="清除图片">
      <i class="el-icon-circle-close" v-show="!editPic" @click="closePic"></i>
    </list-tooltip>
  </div>
</template>

<script>
//引入插件
import { VueCropper } from 'vue-cropper';
import ListTooltip from './ListTooltip.vue';
export default {
  name: 'ImageUpload',
  components: {
    VueCropper,
    ListTooltip
  },
  props: ['editPicData'],

  data() {
    return {
      //控制是否在upload框里显示图片
      isShowImageUrl: false,
      imageUrl: '',
      editPic: true,
      // 裁剪组件的基础配置option
      option: {
        img: '', // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 1, // 裁剪生成图片的质量
        outputType: 'png', // 裁剪生成图片的格式
        canScale: true, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 140, // 默认生成截图框宽度
        autoCropHeight: 140, // 默认生成截图框高度
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: false, // 是否开启截图框宽高固定比例
        fixedNumber: [1, 1], // 截图框的宽高比例
        full: true, // 是否输出原图比例的截图
        canMove: true, //上传图片是否可以移动
        canMoveBox: false, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: false, // 截图框是否被限制在图片里面

        infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
        mode: '61%' // 图片默认渲染方式 contain , cover, 100px, 100% auto
      },

      // 防止重复提交
      loading: false
    };
  },
  methods: {
    imgSaveToUrl(file) {
      const isJPG =
        file.raw.type === 'image/jpeg' ||
        file.raw.type === 'image/png' ||
        file.raw.type === 'image/PNG' ||
        file.raw.type === 'image/jpg';
      const isLt2M = file.size / 1024 / 1024 < 0.1;
      if (!isJPG || !isLt2M) {
        return this.$message('仅支持JPG/JPEG/PNG格式 且文件小于100Kb');
      }
      if (file.raw) {
        var reader = new FileReader();
        reader.onload = e => {
          var base64 = e.target.result;
          var img = document.createElement('img');
          img.src = base64;
          img.onload = () => {
            this.option.infoTrue =
              img.width > 140 || img.height > 140 ? false : true;
            this.option.full =
              img.width > 140 || img.height > 140 ? false : true;
            this.option.img = URL.createObjectURL(file.raw);
            this.imageUrl = URL.createObjectURL(file.raw);
            this.editPic = false;
          };
        };
        reader.readAsDataURL(file.raw);
      }
    },
    finish() {
      if (!this.imageUrl) {
        return this.$message({
          message: '请上传图片',
          type: 'warning'
        });
      }
      // 裁剪后获取blob对象（二进制文件对象）
      this.$refs.cropper.getCropBlob(data => {
        let formData = new FormData();
        formData.append('file', data, 'png.png');
        this.loading = true;
        this.$service.file.fileUploadOther(formData).then(res => {
          if (res.data.status === 200) {
            this.$emit('url', res.data.data.fileUrl);
            this.$emit('uploadPic');
            this.loading = false;
            this.dialogVisible = false;
          }
        });
      });
    },
    // 取消上传图片
    closePic() {
      this.imageUrl = '';
      this.progressFlag = false;
      this.editPic = true;
      this.$emit('url', '');
    }
  },
  watch: {
    editPicData: {
      immediate: true,
      handler(data) {
        if (data.data) {
          this.editPic = false;
          this.option.img = data.data.url;
          this.imageUrl = data.data.url;
        } else {
          this.editPic = true;
          this.option.img = '';
          this.imageUrl = '';
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
.UploadPic {
  background: #fff !important;
  border: 1px dashed #dcdfe6;
  width: 228px;
  height: 140px;
  text-align: center;
  line-height: 140px;
  border-radius: 4px;
  overflow: hidden;
  margin: auto !important;
  transition: 0.1s border-color;
  &:hover {
    transition-delay: 0.1s;
    border: 1px dashed #507cffd0;
  }
  &-imageUrl {
    max-height: 140px !important;
    max-width: 228px !important;
  }
  // 上传提示
  &-explain {
    width: 240px;
    height: 200px;
    font-size: 12px;
    color: #999999;
    &-text {
      position: absolute;
      left: 30px;
      top: 77px;
      width: 169px;
      height: 31px;
      line-height: 20px;
      text-align: center;
    }
    &-icon {
      position: absolute;
      top: -20px;
      left: 78px;
      &-text {
        top: 60px;
        margin-left: 3px;
        width: 57px;
        position: absolute;
        line-height: 14px;
        font-size: 14px;
        color: #333333;
      }
    }
  }
}
// 编辑区域
.cropper {
  width: 228px;
  height: 140px;
  border: 1px dashed #507cff;
  border-radius: 4px;
}
// 取消按钮
.el-icon-circle-close {
  position: absolute;
  left: 220px;
  top: -8px;
  color: #000;
  font-size: 18px;
  cursor: pointer;
  border-radius: 50%;
  background: #fff;
}

/deep/.el-upload-dragger {
  border: none;
}
</style>
